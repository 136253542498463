import React, { useState } from 'react';
import { 
  FaHome, FaChevronRight, FaChartBar, FaDownload, 
  FaFileExport, FaFilter, FaCalendarAlt, FaCar,
  FaGasPump, FaTools, FaUserFriends, FaFileAlt,
  FaFilePdf, FaFileExcel, FaChartPie, FaChartLine, FaChartArea,
  FaExchangeAlt, FaHistory, FaTachometerAlt,
  FaMoneyBill
} from 'react-icons/fa';
import Layout from '../components/Layout';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Reports = () => {
  const [reportType, setReportType] = useState('fleet');
  const [dateRange, setDateRange] = useState('month');
  const [format, setFormat] = useState('pdf');
  const [activeTab, setActiveTab] = useState('reports');

  const reportTypes = [
    {
      id: 'fleet',
      name: 'Fleet Overview',
      icon: FaCar,
      description: 'Vehicle status, utilization, and performance metrics'
    },
    {
      id: 'fuel',
      name: 'Fuel Analysis',
      icon: FaGasPump,
      description: 'Fuel consumption, costs, and efficiency trends'
    },
    {
      id: 'maintenance',
      name: 'Maintenance Report',
      icon: FaTools,
      description: 'Service history, costs, and upcoming maintenance'
    },
    {
      id: 'drivers',
      name: 'Driver Performance',
      icon: FaUserFriends,
      description: 'Driver metrics, safety scores, and trip history'
    }
  ];

  const analyticsSections = [
    {
      id: 'performance',
      name: 'Fleet Performance',
      icon: FaTachometerAlt,
      metrics: [
        { label: 'Total Distance', value: '125,430 km' },
        { label: 'Fuel Efficiency', value: '8.5 L/100km' },
        { label: 'Active Vehicles', value: '85%' }
      ]
    },
    {
      id: 'costs',
      name: 'Cost Analysis',
      icon: FaMoneyBill,
      metrics: [
        { label: 'Fuel Costs', value: '$12,450' },
        { label: 'Maintenance', value: '$8,230' },
        { label: 'Total Expenses', value: '$20,680' }
      ]
    },
    {
      id: 'utilization',
      name: 'Fleet Utilization',
      icon: FaChartPie,
      metrics: [
        { label: 'Average Usage', value: '75%' },
        { label: 'Idle Time', value: '15%' },
        { label: 'Maintenance Time', value: '10%' }
      ]
    },
    {
      id: 'trends',
      name: 'Trends & Forecasts',
      icon: FaChartLine,
      metrics: [
        { label: 'Cost Trend', value: '-5%' },
        { label: 'Usage Trend', value: '+12%' },
        { label: 'Efficiency Trend', value: '+8%' }
      ]
    }
  ];

  // Placeholder data for performance trends
  const performanceData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Fuel Efficiency',
        data: [8.5, 8.2, 8.7, 8.3, 8.1, 8.4],
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.1)',
        fill: true,
        tension: 0.4
      },
      {
        label: 'Distance Covered',
        data: [12500, 13200, 12800, 13500, 13800, 14200],
        borderColor: 'rgb(16, 185, 129)',
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  // Placeholder data for cost analysis
  const costData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Monthly Expenses',
        data: [4500, 5200, 4800, 5100, 4900, 5300],
        backgroundColor: 'rgba(99, 102, 241, 0.5)',
        borderColor: 'rgb(99, 102, 241)',
        borderWidth: 1,
        borderRadius: 4
      }
    ]
  };

  // Placeholder report preview data
  const getReportPreview = () => {
    const previewData = {
      fleet: {
        title: 'Fleet Overview Report',
        summary: [
          { label: 'Total Vehicles', value: '12' },
          { label: 'Active Vehicles', value: '10' },
          { label: 'In Maintenance', value: '2' },
          { label: 'Total Distance', value: '125,430 km' }
        ],
        details: [
          { vehicle: 'Toyota Camry', status: 'Active', driver: 'John Smith', efficiency: '8.2 L/100km' },
          { vehicle: 'Ford Transit', status: 'Maintenance', driver: 'Sarah Wilson', efficiency: '10.5 L/100km' },
          { vehicle: 'Honda CR-V', status: 'Active', driver: 'Mike Johnson', efficiency: '9.1 L/100km' }
        ]
      },
      fuel: {
        title: 'Fuel Analysis Report',
        summary: [
          { label: 'Total Consumption', value: '1,250 L' },
          { label: 'Average Cost/L', value: '$1.52' },
          { label: 'Total Cost', value: '$1,900' },
          { label: 'Avg Efficiency', value: '8.9 L/100km' }
        ],
        details: [
          { date: '2024-02-20', vehicle: 'Toyota Camry', volume: '45.5 L', cost: '$68.25' },
          { date: '2024-02-18', vehicle: 'Ford Transit', volume: '65.0 L', cost: '$97.50' },
          { date: '2024-02-15', vehicle: 'Honda CR-V', volume: '52.3 L', cost: '$78.45' }
        ]
      },
      // ... add more report types as needed
    };

    return previewData[reportType] || previewData.fleet;
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          borderDash: [2, 4],
          drawBorder: false
        }
      }
    }
  };

  const handleGenerateReport = () => {
    // Implementation for report generation
    console.log('Generating report:', { reportType, dateRange, format });
  };

  const handleExportData = (format) => {
    // Implementation for data export
    console.log('Exporting data as:', format);
  };

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
      

        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header with Tabs */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div>
                <h1 className="text-2xl font-semibold text-[#262626]">Reports & Analytics</h1>
                <p className="text-sm text-gray-500 mt-1">Generate reports and analyze fleet data</p>
              </div>
              <div className="flex items-center space-x-1 bg-gray-100/80 rounded-lg p-1">
                <button
                  onClick={() => setActiveTab('analytics')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                    ${activeTab === 'analytics' 
                      ? 'bg-white text-blue-600 shadow-sm ring-1 ring-gray-200/60' 
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`}
                >
                  Analytics Dashboard
                </button>
                <button
                  onClick={() => setActiveTab('reports')}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
                    ${activeTab === 'reports' 
                      ? 'bg-white text-blue-600 shadow-sm ring-1 ring-gray-200/60' 
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'}`}
                >
                  Report Generator
                </button>
              </div>
            </div>
          </div>

          {activeTab === 'analytics' ? (
            <>
              {/* Analytics Dashboard */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {analyticsSections.map((section) => (
                  <div
                    key={section.id}
                    className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
                      hover:shadow-md transition-shadow duration-200"
                  >
                    <div className="flex items-center space-x-3 mb-4">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <section.icon size={20} className="text-blue-500" />
                      </div>
                      <h3 className="font-medium text-gray-900">{section.name}</h3>
                    </div>
                    <div className="space-y-3">
                      {section.metrics.map((metric, index) => (
                        <div key={index} className="flex justify-between items-center py-1">
                          <span className="text-sm text-gray-500">{metric.label}</span>
                          <span className={`text-sm font-medium ${
                            metric.value.includes('+') 
                              ? 'text-green-600' 
                              : metric.value.includes('-') 
                                ? 'text-red-600' 
                                : 'text-gray-900'
                          }`}>
                            {metric.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {/* Charts Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
                  <div className="flex items-center justify-between mb-6">
                    <div>
                      <h3 className="font-medium text-gray-900">Performance Trends</h3>
                      <p className="text-sm text-gray-500 mt-1">6-month performance analysis</p>
                    </div>
                    <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                      View Details
                    </button>
                  </div>
                  <div className="h-[300px]">
                    <Line data={performanceData} options={chartOptions} />
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
                  <div className="flex items-center justify-between mb-6">
                    <div>
                      <h3 className="font-medium text-gray-900">Cost Analysis</h3>
                      <p className="text-sm text-gray-500 mt-1">Monthly expense breakdown</p>
                    </div>
                    <button className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                      View Details
                    </button>
                  </div>
                  <div className="h-[300px]">
                    <Bar data={costData} options={chartOptions} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Report Generator */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
                <div className="flex flex-col md:flex-row md:items-center gap-4">
                  <div className="flex-1 flex flex-col md:flex-row md:items-center gap-4">
                    {/* Report Type Filter */}
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Report Type
                      </label>
                      <div className="flex items-center space-x-2">
                        <div className="flex-1 flex items-center space-x-2 bg-white px-3 py-2.5 border 
                          border-gray-300 rounded-lg shadow-sm">
                          <FaFilter className="text-gray-400" />
                          <select
                            value={reportType}
                            onChange={(e) => setReportType(e.target.value)}
                            className="w-full border-0 focus:outline-none focus:ring-0 text-sm 
                              text-gray-600 bg-transparent"
                          >
                            {reportTypes.map(type => (
                              <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Date Range Filter */}
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Date Range
                      </label>
                      <div className="flex items-center space-x-2">
                        <div className="flex-1 flex items-center space-x-2 bg-white px-3 py-2.5 border 
                          border-gray-300 rounded-lg shadow-sm">
                          <FaCalendarAlt className="text-gray-400" />
                          <select
                            value={dateRange}
                            onChange={(e) => setDateRange(e.target.value)}
                            className="w-full border-0 focus:outline-none focus:ring-0 text-sm 
                              text-gray-600 bg-transparent"
                          >
                            <option value="week">Last Week</option>
                            <option value="month">Last Month</option>
                            <option value="quarter">Last Quarter</option>
                            <option value="year">Last Year</option>
                            <option value="custom">Custom Range</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center space-x-3">
                    <button 
                      onClick={() => handleExportData('csv')}
                      className="inline-flex items-center px-4 py-2.5 text-sm font-medium text-gray-700 
                        bg-white border border-gray-300 rounded-lg hover:bg-gray-50 
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FaFileExcel className="mr-2" size={16} />
                      Export CSV
                    </button>
                    <button 
                      onClick={handleGenerateReport}
                      className="inline-flex items-center px-5 py-2.5 bg-blue-600 text-white 
                        rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 
                        focus:ring-blue-500 focus:ring-offset-2 shadow-sm font-medium"
                    >
                      <FaFileAlt className="mr-2" size={16} />
                      Generate Report
                    </button>
                  </div>
                </div>
              </div>

              {/* Report Types Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {reportTypes.map((type) => (
                  <button
                    key={type.id}
                    onClick={() => setReportType(type.id)}
                    className={`p-6 bg-white rounded-xl shadow-sm border transition-all duration-200
                      ${reportType === type.id 
                        ? 'border-blue-500 ring-2 ring-blue-500 ring-opacity-50 shadow-md' 
                        : 'border-gray-200/60 hover:border-blue-500 hover:shadow-md'}`}
                  >
                    <div className="flex flex-col items-center text-center">
                      <div className={`p-3 rounded-lg ${
                        reportType === type.id ? 'bg-blue-50' : 'bg-gray-50'
                      }`}>
                        <type.icon 
                          size={24} 
                          className={reportType === type.id ? 'text-blue-500' : 'text-gray-400'} 
                        />
                      </div>
                      <h3 className="mt-4 font-medium text-gray-900">{type.name}</h3>
                      <p className="mt-2 text-sm text-gray-500">{type.description}</p>
                    </div>
                  </button>
                ))}
              </div>

              {/* Preview Section */}
              <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 p-6">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h2 className="text-lg font-semibold text-[#262626]">Report Preview</h2>
                    <p className="text-sm text-gray-500">Preview your report before generating</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="flex items-center space-x-2 bg-white px-3 py-2 border 
                      border-gray-300 rounded-lg shadow-sm">
                      <FaFileExport className="text-gray-400" />
                      <select
                        value={format}
                        onChange={(e) => setFormat(e.target.value)}
                        className="border-0 focus:outline-none focus:ring-0 text-sm 
                          text-gray-600 bg-transparent pr-8"
                      >
                        <option value="pdf">PDF Format</option>
                        <option value="csv">CSV Format</option>
                        <option value="excel">Excel Format</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 border border-gray-200 rounded-lg">
                  {reportType && (
                    <div className="p-6">
                      <h3 className="text-xl font-semibold mb-6">{getReportPreview().title}</h3>
                      
                      {/* Summary Section */}
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
                        {getReportPreview().summary.map((item, index) => (
                          <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                            <div className="text-sm text-gray-500">{item.label}</div>
                            <div className="text-lg font-semibold text-gray-900">{item.value}</div>
                          </div>
                        ))}
                      </div>

                      {/* Details Table */}
                      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {Object.keys(getReportPreview().details[0]).map((header, index) => (
                                <th
                                  key={index}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {getReportPreview().details.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td
                                    key={cellIndex}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                  >
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Reports; 