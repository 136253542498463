import React from 'react';
import { FaTable, FaThLarge } from 'react-icons/fa';

const ViewToggle = ({ view, onViewChange }) => {
  return (
    <div className="flex items-center bg-white border border-gray-300 rounded-lg shadow-sm">
      <button
        onClick={() => onViewChange('grid')}
        className={`flex items-center px-3 py-2 rounded-l-lg ${
          view === 'grid'
            ? 'bg-blue-50 text-blue-600'
            : 'text-gray-600 hover:bg-gray-50'
        }`}
      >
        <FaThLarge size={16} />
        <span className="ml-2 text-sm font-medium">Grid</span>
      </button>
      <div className="w-px h-6 bg-gray-300" />
      <button
        onClick={() => onViewChange('table')}
        className={`flex items-center px-3 py-2 rounded-r-lg ${
          view === 'table'
            ? 'bg-blue-50 text-blue-600'
            : 'text-gray-600 hover:bg-gray-50'
        }`}
      >
        <FaTable size={16} />
        <span className="ml-2 text-sm font-medium">Table</span>
      </button>
    </div>
  );
};

export default ViewToggle; 