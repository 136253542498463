// frontend/src/services/authService.js

import axios from 'axios';

const API_URL = 'http://localhost:5000/api/auth/';

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  // Add timeout and additional config
  timeout: 5000,
});

// Test connection function
const testConnection = async () => {
  try {
    // Instead of trying to access the base URL, let's try a HEAD request to the login endpoint
    const response = await axios.head(API_URL + 'login', {
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Connection test successful:', response.status);
    return true;
  } catch (error) {
    if (error.response) {
      // If we get any response, even an error response, the server is up
      console.log('Server is reachable, status:', error.response.status);
      return true;
    }
    console.error('Connection test failed:', error.message);
    return false;
  }
};

// Register user
const register = async (userData) => {
  try {
    const response = await axiosInstance.post('register', userData);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error('Register error:', error);
    throw error;
  }
};

// Login user
const login = async (userData) => {
  try {
    // Test connection before making the login request
    console.log('Attempting to connect to:', API_URL);
    
    const response = await axiosInstance.post('login', userData);
    console.log('Login response:', response);
    
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    if (error.code === 'ECONNABORTED') {
      throw new Error('Request timed out. Server might be down.');
    }
    if (!error.response) {
      console.error('Network error details:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      // Try alternative fetch method if axios fails
      try {
        const fetchResponse = await fetch(`${API_URL}login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData)
        });
        const data = await fetchResponse.json();
        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        return data;
      } catch (fetchError) {
        console.error('Fetch fallback failed:', fetchError);
        throw fetchError;
      }
    }
    throw error;
  }
};

// Logout user
const logout = () => {
  localStorage.removeItem('token');
};

const authService = {
  register,
  login,
  logout,
  testConnection // Export the test function
};

export default authService;
