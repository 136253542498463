import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white border-t border-gray-200/80">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-6">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
          <div className="text-sm text-gray-500">
            © {currentYear} Fleet Management System. All rights reserved.
          </div>
          <div className="text-sm text-gray-500">
            Designed by{' '}
            <a 
              href="https://samuelhailemariamseifu.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 font-medium"
            >
              Samuel Hailemariam Seifu
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 