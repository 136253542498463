import React, { useState } from 'react';
import { 
  FaBell, FaSearch, FaUserCircle, FaCog, FaSignOutAlt, 
  FaChevronDown, FaBars 
} from 'react-icons/fa';

const Header = ({ toggleSidebar }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const notifications = [
    {
      id: 1,
      message: 'Maintenance due for Vehicle ABC-123',
      time: '5 minutes ago'
    },
    {
      id: 2,
      message: 'Low fuel alert for Vehicle XYZ-789',
      time: '10 minutes ago'
    },
    {
      id: 3,
      message: 'New driver assignment pending',
      time: '1 hour ago'
    }
  ];

  return (
    <header className="bg-white border-b border-gray-200/80 sticky top-0 z-30">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16">
          {/* Left side */}
          <div className="flex items-center">
            <button
              onClick={toggleSidebar}
              className="p-2 rounded-lg text-gray-400 hover:text-gray-600 hover:bg-gray-100 lg:hidden"
            >
              <FaBars size={20} />
            </button>
            <div className="relative ml-4">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="search"
                placeholder="Search..."
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg 
                  leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 
                  focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-4">
            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                className="p-2 rounded-lg text-gray-400 hover:text-gray-600 hover:bg-gray-100 relative"
              >
                <FaBell size={20} />
                <span className="absolute top-1.5 right-1.5 block h-2 w-2 rounded-full bg-red-500" />
              </button>

              {/* Notifications Dropdown */}
              {isNotificationsOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg py-1 
                  border border-gray-200 z-50">
                  {notifications.map(notification => (
                    <div
                      key={notification.id}
                      className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
                    >
                      <p className="text-sm text-gray-900">{notification.message}</p>
                      <p className="text-xs text-gray-500 mt-1">{notification.time}</p>
                    </div>
                  ))}
                  <div className="border-t border-gray-100 mt-1">
                    <a
                      href="/notifications"
                      className="block px-4 py-2 text-sm text-blue-600 hover:text-blue-700 
                        hover:bg-gray-50"
                    >
                      View all notifications
                    </a>
                  </div>
                </div>
              )}
            </div>

            {/* Profile Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center space-x-2 p-2 rounded-lg text-gray-600 
                  hover:text-gray-900 hover:bg-gray-100"
              >
                <FaUserCircle size={24} />
                <span className="text-sm font-medium hidden sm:block">John Doe</span>
                <FaChevronDown size={12} className="hidden sm:block" />
              </button>

              {/* Profile Menu */}
              {isProfileOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 
                  border border-gray-200 z-50">
                  <a
                    href="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 
                      flex items-center space-x-2"
                  >
                    <FaUserCircle size={16} />
                    <span>Profile</span>
                  </a>
                  <a
                    href="/settings"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 
                      flex items-center space-x-2"
                  >
                    <FaCog size={16} />
                    <span>Settings</span>
                  </a>
                  <div className="border-t border-gray-100 mt-1">
                    <a
                      href="/logout"
                      className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-50 
                        flex items-center space-x-2"
                    >
                      <FaSignOutAlt size={16} />
                      <span>Sign out</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header; 