import React, { useState, useEffect } from 'react';
import { 
  FaHome, FaChevronRight, FaCar, FaSearch, 
  FaFilter, FaPlus, FaEllipsisV, FaGasPump,
  FaMapMarkerAlt, FaUserAlt, FaCircle, FaEdit,
  FaTrash, FaTimes
} from 'react-icons/fa';
import Layout from '../components/Layout';
import ViewToggle from '../components/ViewToggle';

const Vehicles = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicles, setVehicles] = useState([
    {
      id: 1,
      name: 'Toyota Camry',
      plateNumber: 'ABC-123',
      status: 'active',
      driver: 'John Smith',
      location: '123 Main St, City',
      fuelLevel: 75,
      lastMaintenance: '2024-01-15',
      type: 'Sedan',
      mileage: '45,234'
    },
    {
      id: 2,
      name: 'Ford Transit',
      plateNumber: 'XYZ-789',
      status: 'maintenance',
      driver: 'Sarah Wilson',
      location: '456 Oak Ave, Town',
      fuelLevel: 45,
      lastMaintenance: '2024-02-01',
      type: 'Van',
      mileage: '32,109'
    },
    {
      id: 3,
      name: 'Honda CR-V',
      plateNumber: 'DEF-456',
      status: 'inactive',
      driver: 'Mike Johnson',
      location: '789 Pine Rd, Village',
      fuelLevel: 30,
      lastMaintenance: '2024-01-20',
      type: 'SUV',
      mileage: '28,567'
    }
  ]);

  // Form state for add/edit
  const [formData, setFormData] = useState({
    name: '',
    plateNumber: '',
    status: 'active',
    driver: '',
    location: '',
    fuelLevel: 100,
    type: '',
    mileage: ''
  });

  // Add state for managing dropdown menus
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.dropdown-container')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddVehicle = (e) => {
    e.preventDefault();
    const newVehicle = {
      id: vehicles.length + 1,
      ...formData,
      lastMaintenance: new Date().toISOString().split('T')[0]
    };
    setVehicles(prev => [...prev, newVehicle]);
    setIsAddModalOpen(false);
    setFormData({
      name: '',
      plateNumber: '',
      status: 'active',
      driver: '',
      location: '',
      fuelLevel: 100,
      type: '',
      mileage: ''
    });
  };

  const handleEditVehicle = (e) => {
    e.preventDefault();
    setVehicles(prev => prev.map(vehicle => 
      vehicle.id === selectedVehicle.id ? { ...vehicle, ...formData } : vehicle
    ));
    setIsEditModalOpen(false);
    setSelectedVehicle(null);
  };

  const handleDeleteVehicle = () => {
    setVehicles(prev => prev.filter(vehicle => vehicle.id !== selectedVehicle.id));
    setIsDeleteModalOpen(false);
    setSelectedVehicle(null);
  };

  const openEditModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setFormData(vehicle);
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsDeleteModalOpen(true);
  };

  const getStatusColor = (status) => {
    const colors = {
      active: 'text-green-600 bg-green-50 border-green-100',
      maintenance: 'text-yellow-600 bg-yellow-50 border-yellow-100',
      inactive: 'text-red-600 bg-red-50 border-red-100'
    };
    return colors[status] || colors.active;
  };

  const getFuelLevelColor = (level) => {
    if (level > 70) return 'text-green-600';
    if (level > 30) return 'text-yellow-600';
    return 'text-red-600';
  };

  const filteredVehicles = vehicles.filter(vehicle => {
    const matchesSearch = vehicle.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         vehicle.plateNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         vehicle.driver.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filterStatus === 'all' || vehicle.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  // Modal Component
  const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-xl mx-4">
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <FaTimes className="text-gray-500" />
            </button>
          </div>
          <div className="p-6">
            {children}
          </div>
        </div>
      </div>
    );
  };

  // Vehicle Form Component
  const VehicleForm = ({ onSubmit, initialData = formData, buttonText }) => (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Vehicle Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Plate Number</label>
          <input
            type="text"
            name="plateNumber"
            value={formData.plateNumber}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="active">Active</option>
            <option value="maintenance">Maintenance</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Driver</label>
          <input
            type="text"
            name="driver"
            value={formData.driver}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Fuel Level (%)</label>
          <input
            type="number"
            name="fuelLevel"
            value={formData.fuelLevel}
            onChange={handleInputChange}
            min="0"
            max="100"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
      </div>
      <div className="flex justify-end space-x-3 pt-4">
        <button
          type="button"
          onClick={() => {
            setIsAddModalOpen(false);
            setIsEditModalOpen(false);
          }}
          className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
            transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
            transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );

  const [view, setView] = useState('grid');

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
       

        {/* Main Content */}
        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 p-4 sm:p-6">
            <div className="flex flex-col space-y-4">
              {/* Title and Add Button */}
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 text-center sm:text-left">
                <div>
                  <h1 className="text-2xl font-semibold text-[#262626]">Vehicles</h1>
                  <p className="text-sm text-gray-500 mt-1">Manage your fleet vehicles</p>
                </div>
                <button 
                  onClick={() => setIsAddModalOpen(true)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-5 py-2.5 
                    bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                    shadow-sm font-medium"
                >
                  <FaPlus className="mr-2" size={16} />
                  Add Vehicle
                </button>
              </div>

              {/* Filters Section */}
              <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center gap-4">
                {/* Search */}
                <div className="flex-1 relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search vehicles..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      bg-white shadow-sm"
                  />
                </div>

                {/* Status Filter and View Toggle */}
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
                  <div className="w-full sm:w-auto">
                    <div className="flex items-center space-x-2 bg-white px-3 py-2 border 
                      border-gray-300 rounded-lg shadow-sm w-full sm:w-auto">
                      <FaFilter className="text-gray-400" />
                      <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="w-full sm:w-auto border-0 focus:outline-none focus:ring-0 
                          text-sm text-gray-600 bg-transparent pr-8"
                      >
                        <option value="all">All Status</option>
                        <option value="active">Active</option>
                        <option value="maintenance">In Maintenance</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <ViewToggle view={view} onViewChange={setView} />
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          {view === 'grid' ? (
            // Existing grid view remains the same
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredVehicles.map((vehicle) => (
                <div 
                  key={vehicle.id}
                  className="bg-white rounded-lg shadow-sm border border-gray-200/60 hover:shadow-md 
                    transition-all duration-200 overflow-hidden group"
                >
                  <div className="p-6">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <h3 className="text-lg font-semibold text-[#262626] group-hover:text-blue-600 
                          transition-colors">
                          {vehicle.name}
                        </h3>
                        <p className="text-[#8c8c8c] text-sm">{vehicle.plateNumber}</p>
                      </div>
                      <div className="relative dropdown-container">
                        <button 
                          onClick={() => setActiveDropdown(activeDropdown === vehicle.id ? null : vehicle.id)}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <FaEllipsisV className="text-gray-400" size={16} />
                        </button>
                        {activeDropdown === vehicle.id && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
                            border border-gray-200 py-1 z-10">
                            <button
                              onClick={() => {
                                openEditModal(vehicle);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-gray-700 
                                hover:bg-gray-100 flex items-center"
                            >
                              <FaEdit className="mr-2" size={14} />
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                openDeleteModal(vehicle);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-red-600 
                                hover:bg-red-50 flex items-center"
                            >
                              <FaTrash className="mr-2" size={14} />
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Details */}
                    <div className="space-y-3">
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaUserAlt className="mr-2 text-gray-400" size={14} />
                        {vehicle.driver}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaMapMarkerAlt className="mr-2 text-gray-400" size={14} />
                        {vehicle.location}
                      </div>
                      <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center text-[#595959]">
                          <FaGasPump className={`mr-2 ${getFuelLevelColor(vehicle.fuelLevel)}`} size={14} />
                          Fuel Level: {vehicle.fuelLevel}%
                        </div>
                        <div className="text-[#8c8c8c]">
                          {vehicle.mileage} km
                        </div>
                      </div>
                    </div>

                    {/* Progress bar for fuel level */}
                    <div className="mt-4 h-1.5 bg-gray-100 rounded-full overflow-hidden">
                      <div 
                        className={`h-full rounded-full transition-all duration-300
                          ${vehicle.fuelLevel > 70 ? 'bg-green-500' :
                            vehicle.fuelLevel > 30 ? 'bg-yellow-500' : 'bg-red-500'}`}
                        style={{ width: `${vehicle.fuelLevel}%` }}
                      />
                    </div>
                  </div>

                  {/* Bottom border */}
                  <div className="h-1 bg-blue-500 transform origin-left scale-x-0 
                    group-hover:scale-x-100 transition-transform duration-300" />
                </div>
              ))}
            </div>
          ) : (
            // New table view
            <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Vehicle
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Driver
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Fuel Level
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Mileage
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredVehicles.map((vehicle) => (
                      <tr 
                        key={vehicle.id}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaCar className="text-gray-400 mr-2" size={16} />
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {vehicle.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {vehicle.plateNumber}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`
                            px-2.5 py-1 rounded-full text-xs font-medium border
                            ${getStatusColor(vehicle.status)}
                          `}>
                            {vehicle.status.charAt(0).toUpperCase() + vehicle.status.slice(1)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaUserAlt className="text-gray-400 mr-2" size={14} />
                            <span className="text-sm text-gray-900">{vehicle.driver}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaMapMarkerAlt className="text-gray-400 mr-2" size={14} />
                            <span className="text-sm text-gray-900">{vehicle.location}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center space-x-2">
                            <FaGasPump className={getFuelLevelColor(vehicle.fuelLevel)} size={14} />
                            <span className="text-sm text-gray-900">{vehicle.fuelLevel}%</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {vehicle.mileage} km
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-2">
                            <button
                              onClick={() => openEditModal(vehicle)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <FaEdit size={16} />
                            </button>
                            <button
                              onClick={() => openDeleteModal(vehicle)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <FaTrash size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add New Vehicle"
      >
        <VehicleForm onSubmit={handleAddVehicle} buttonText="Add Vehicle" />
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Vehicle"
      >
        <VehicleForm onSubmit={handleEditVehicle} buttonText="Save Changes" />
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Vehicle"
      >
        <div className="space-y-4">
          <p className="text-gray-700">
            Are you sure you want to delete this vehicle? This action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteVehicle}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 
                transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Vehicles;
