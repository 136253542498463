import React, { useState, useEffect } from "react";
import {
  FaHome,
  FaChevronRight,
  FaGasPump,
  FaSearch,
  FaFilter,
  FaPlus,
  FaEllipsisV,
  FaCar,
  FaCalendarAlt,
  FaMoneyBillWave,
  FaEdit,
  FaTrash,
  FaTimes,
} from "react-icons/fa";
import Layout from "../components/Layout";
import ViewToggle from "../components/ViewToggle";
import Modal from "../components/Modal";

const FuelManagement = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterVehicle, setFilterVehicle] = useState("all");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [view, setView] = useState("grid");

  const [fuelRecords, setFuelRecords] = useState([
    {
      id: 1,
      vehicle: "Toyota Camry (ABC-123)",
      date: "2024-02-20",
      liters: 45.5,
      cost: 68.25,
      odometer: "45,234",
      fuelType: "Regular",
      pricePerLiter: 1.5,
      location: "Shell Station - Main St",
      efficiency: 8.2, // L/100km
    },
    {
      id: 2,
      vehicle: "Ford Transit (XYZ-789)",
      date: "2024-02-18",
      liters: 65.0,
      cost: 97.5,
      odometer: "32,109",
      fuelType: "Diesel",
      pricePerLiter: 1.45,
      location: "BP Station - Oak Ave",
      efficiency: 10.5,
    },
    {
      id: 3,
      vehicle: "Honda CR-V (DEF-456)",
      date: "2024-02-15",
      liters: 52.3,
      cost: 78.45,
      odometer: "28,567",
      fuelType: "Premium",
      pricePerLiter: 1.65,
      location: "Mobil Station - Pine Rd",
      efficiency: 9.1,
    },
  ]);

  // Form state
  const [formData, setFormData] = useState({
    vehicle: "",
    date: "",
    liters: "",
    cost: "",
    odometer: "",
    fuelType: "Regular",
    location: "",
    pricePerLiter: "",
    efficiency: ""
  });

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest(".dropdown-container")) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [activeDropdown]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Calculate fuel efficiency metrics
  const getEfficiencyColor = (efficiency) => {
    if (efficiency < 8) return "text-green-600";
    if (efficiency < 10) return "text-yellow-600";
    return "text-red-600";
  };

  const filteredRecords = fuelRecords.filter((record) => {
    const matchesSearch =
      record.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      record.location.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter =
      filterVehicle === "all" || record.vehicle.includes(filterVehicle);
    return matchesSearch && matchesFilter;
  });

  // Add Modal Functions
  const handleAddClick = () => {
    setFormData({
      vehicle: "",
      date: "",
      liters: "",
      cost: "",
      odometer: "",
      fuelType: "Regular",
      location: "",
      pricePerLiter: "",
      efficiency: ""
    });
    setIsAddModalOpen(true);
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    const newRecord = {
      id: fuelRecords.length + 1,
      ...formData,
      efficiency: calculateEfficiency(formData.liters, formData.odometer)
    };
    setFuelRecords([...fuelRecords, newRecord]);
    setIsAddModalOpen(false);
  };

  // Edit Modal Functions
  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setFormData(record);
    setIsEditModalOpen(true);
    setActiveDropdown(null);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const updatedRecords = fuelRecords.map(record => 
      record.id === selectedRecord.id 
        ? { ...record, ...formData }
        : record
    );
    setFuelRecords(updatedRecords);
    setIsEditModalOpen(false);
    setSelectedRecord(null);
  };

  // Delete Modal Functions
  const handleDeleteClick = (record) => {
    setSelectedRecord(record);
    setIsDeleteModalOpen(true);
    setActiveDropdown(null);
  };

  const handleDeleteConfirm = () => {
    setFuelRecords(fuelRecords.filter(record => record.id !== selectedRecord.id));
    setIsDeleteModalOpen(false);
    setSelectedRecord(null);
  };

  // Helper function to calculate efficiency
  const calculateEfficiency = (liters, odometer) => {
    // Simple calculation - can be adjusted based on your needs
    return (liters / odometer) * 100;
  };

  // Update the Grid View buttons
  const renderGridActions = (record) => (
    <div className="relative dropdown-container">
      <button
        onClick={() => setActiveDropdown(activeDropdown === record.id ? null : record.id)}
        className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
      >
        <FaEllipsisV className="text-gray-400" size={16} />
      </button>
      {activeDropdown === record.id && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
          border border-gray-200 py-1 z-10"
        >
          <button
            onClick={() => handleEditClick(record)}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 
              hover:bg-gray-100 flex items-center"
          >
            <FaEdit className="mr-2" size={14} />
            Edit
          </button>
          <button
            onClick={() => handleDeleteClick(record)}
            className="w-full px-4 py-2 text-left text-sm text-red-600 
              hover:bg-red-50 flex items-center"
          >
            <FaTrash className="mr-2" size={14} />
            Delete
          </button>
        </div>
      )}
    </div>
  );

  // Update the Table View buttons
  const renderTableActions = (record) => (
    <div className="flex items-center justify-end space-x-2">
      <button
        onClick={() => handleEditClick(record)}
        className="text-blue-600 hover:text-blue-900"
      >
        <FaEdit size={16} />
      </button>
      <button
        onClick={() => handleDeleteClick(record)}
        className="text-red-600 hover:text-red-900"
      >
        <FaTrash size={16} />
      </button>
    </div>
  );

  // Add the Modals
  const renderModals = () => (
    <>
      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add Fuel Record"
      >
        <form onSubmit={handleAddSubmit} className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Vehicle</label>
              <input
                type="text"
                name="vehicle"
                value={formData.vehicle}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Liters</label>
              <input
                type="number"
                name="liters"
                value={formData.liters}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.1"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Cost</label>
              <input
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.01"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Odometer</label>
              <input
                type="text"
                name="odometer"
                value={formData.odometer}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Fuel Type</label>
              <select
                name="fuelType"
                value={formData.fuelType}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              >
                <option value="Regular">Regular</option>
                <option value="Premium">Premium</option>
                <option value="Diesel">Diesel</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price per Liter</label>
              <input
                type="number"
                name="pricePerLiter"
                value={formData.pricePerLiter}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.01"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Location</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsAddModalOpen(false)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Add Record
            </button>
          </div>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Fuel Record"
      >
        <form onSubmit={handleEditSubmit} className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Vehicle</label>
              <input
                type="text"
                name="vehicle"
                value={formData.vehicle}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Liters</label>
              <input
                type="number"
                name="liters"
                value={formData.liters}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.1"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Cost</label>
              <input
                type="number"
                name="cost"
                value={formData.cost}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.01"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Odometer</label>
              <input
                type="text"
                name="odometer"
                value={formData.odometer}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Fuel Type</label>
              <select
                name="fuelType"
                value={formData.fuelType}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              >
                <option value="Regular">Regular</option>
                <option value="Premium">Premium</option>
                <option value="Diesel">Diesel</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price per Liter</label>
              <input
                type="number"
                name="pricePerLiter"
                value={formData.pricePerLiter}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                step="0.01"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Location</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsEditModalOpen(false)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Fuel Record"
      >
        <div className="p-6">
          <p className="text-gray-600">
            Are you sure you want to delete this fuel record? This action cannot be undone.
          </p>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteConfirm}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
     

        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 p-4 sm:p-6">
            <div className="flex flex-col space-y-4">
              {/* Title and Add Button */}
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 text-center sm:text-left">
                <div>
                  <h1 className="text-2xl font-semibold text-[#262626]">Fuel Management</h1>
                  <p className="text-sm text-gray-500 mt-1">Track fuel consumption and costs</p>
                </div>
                <button 
                  onClick={() => setIsAddModalOpen(true)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-5 py-2.5 
                    bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                    shadow-sm font-medium"
                >
                  <FaPlus className="mr-2" size={16} />
                  Add Fuel Record
                </button>
              </div>

              {/* Filters Section */}
              <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center gap-4">
                {/* Search */}
                <div className="flex-1 relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search records..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      bg-white shadow-sm"
                  />
                </div>

                {/* Vehicle Filter and View Toggle */}
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
                  <div className="w-full sm:w-auto">
                    <div className="flex items-center space-x-2 bg-white px-3 py-2 border 
                      border-gray-300 rounded-lg shadow-sm w-full sm:w-auto">
                      <FaFilter className="text-gray-400" />
                      <select
                        value={filterVehicle}
                        onChange={(e) => setFilterVehicle(e.target.value)}
                        className="w-full sm:w-auto border-0 focus:outline-none focus:ring-0 
                          text-sm text-gray-600 bg-transparent pr-8"
                      >
                        <option value="all">All Vehicles</option>
                        {/* Vehicle options */}
                      </select>
                    </div>
                  </div>
                  <ViewToggle view={view} onViewChange={setView} />
                </div>
              </div>
            </div>
          </div>

          {/* Content Section */}
          {view === "grid" ? (
            // Grid View
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredRecords.map((record) => (
                <div
                  key={record.id}
                  className="bg-white rounded-lg shadow-sm border border-gray-200/60 hover:shadow-md 
                    transition-all duration-200 overflow-hidden group"
                >
                  <div className="p-6">
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <div className="flex items-center space-x-2">
                          <FaCar className="text-gray-400" size={18} />
                          <h3
                            className="text-lg font-semibold text-[#262626] group-hover:text-blue-600 
                            transition-colors"
                          >
                            {record.vehicle}
                          </h3>
                        </div>
                        <p className="text-sm text-gray-500 mt-1">
                          {record.date}
                        </p>
                      </div>
                      <div className="relative dropdown-container">
                        <button
                          onClick={() =>
                            setActiveDropdown(
                              activeDropdown === record.id ? null : record.id
                            )
                          }
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <FaEllipsisV className="text-gray-400" size={16} />
                        </button>
                        {activeDropdown === record.id && (
                          <div
                            className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
                            border border-gray-200 py-1 z-10"
                          >
                            <button
                              onClick={() => handleEditClick(record)}
                              className="w-full px-4 py-2 text-left text-sm text-gray-700 
                                hover:bg-gray-100 flex items-center"
                            >
                              <FaEdit className="mr-2" size={14} />
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeleteClick(record)}
                              className="w-full px-4 py-2 text-left text-sm text-red-600 
                                hover:bg-red-50 flex items-center"
                            >
                              <FaTrash className="mr-2" size={14} />
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="space-y-3">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <div className="text-sm text-gray-500">Volume</div>
                          <div className="text-sm font-medium">
                            {record.liters.toFixed(1)} L
                          </div>
                        </div>
                        <div>
                          <div className="text-sm text-gray-500">Cost</div>
                          <div className="text-sm font-medium">
                            ${record.cost.toFixed(2)}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm text-gray-500">Price/L</div>
                          <div className="text-sm font-medium">
                            ${record.pricePerLiter.toFixed(2)}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm text-gray-500">
                            Efficiency
                          </div>
                          <div
                            className={`text-sm font-medium ${getEfficiencyColor(
                              record.efficiency
                            )}`}
                          >
                            {record.efficiency.toFixed(1)} L/100km
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">Location</div>
                        <div className="text-sm font-medium">
                          {record.location}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">Odometer</div>
                        <div className="text-sm font-medium">
                          {record.odometer} km
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Table View
            <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Vehicle
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Volume
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cost
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Efficiency
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredRecords.map((record) => (
                      <tr
                        key={record.id}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaCar className="text-gray-400 mr-2" size={16} />
                            <div className="text-sm font-medium text-gray-900">
                              {record.vehicle}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {record.date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {record.liters.toFixed(1)} L
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          ${record.cost.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={`text-sm font-medium ${getEfficiencyColor(
                              record.efficiency
                            )}`}
                          >
                            {record.efficiency.toFixed(1)} L/100km
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {record.location}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-2">
                            <button
                              onClick={() => handleEditClick(record)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <FaEdit size={16} />
                            </button>
                            <button
                              onClick={() => handleDeleteClick(record)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <FaTrash size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ... existing modals ... */}
      {renderModals()}
    </Layout>
  );
};

export default FuelManagement;
