// src/pages/Dashboard.js

import React, { useState } from 'react';
import { 
  FaCar, FaUsers, FaGasPump, FaTools, FaArrowUp, FaArrowDown,
  FaCalendar, FaCheckCircle, FaRoute, FaChartLine, FaExclamationTriangle
} from 'react-icons/fa';
import Layout from '../components/Layout';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Dashboard = () => {
  const [timeRange, setTimeRange] = useState('week');

  // Stats data
  const stats = [
    {
      title: 'Active Vehicles',
      value: '24',
      change: '+4.5%',
      isIncrease: true,
      icon: FaCar,
      bgColor: 'bg-blue-500/10',
      iconColor: 'text-blue-600',
      borderColor: 'border-blue-200'
    },
    {
      title: 'Active Drivers',
      value: '18',
      change: '+2.8%',
      isIncrease: true,
      icon: FaUsers,
      bgColor: 'bg-green-500/10',
      iconColor: 'text-green-600',
      borderColor: 'border-green-200'
    },
    {
      title: 'Fuel Consumption',
      value: '2,456L',
      change: '-1.2%',
      isIncrease: false,
      icon: FaGasPump,
      bgColor: 'bg-purple-500/10',
      iconColor: 'text-purple-600',
      borderColor: 'border-purple-200'
    },
    {
      title: 'Maintenance Due',
      value: '5',
      change: '+1',
      isIncrease: true,
      icon: FaTools,
      bgColor: 'bg-orange-500/10',
      iconColor: 'text-orange-600',
      borderColor: 'border-orange-200'
    }
  ];

  // Recent activities with enhanced styling
  const activities = [
    {
      id: 1,
      type: 'maintenance',
      title: 'Vehicle Maintenance Completed',
      description: 'Toyota Camry (ABC-123) regular service completed',
      time: '2 hours ago',
      icon: FaCheckCircle,
      color: 'text-green-500 bg-green-50'
    },
    {
      id: 2,
      type: 'alert',
      title: 'Low Fuel Alert',
      description: 'Ford Transit (XYZ-789) fuel level below 15%',
      time: '3 hours ago',
      icon: FaGasPump,
      color: 'text-orange-500 bg-orange-50'
    },
    {
      id: 3,
      type: 'route',
      title: 'New Route Assigned',
      description: 'Route #123 assigned to John Smith',
      time: '5 hours ago',
      icon: FaRoute,
      color: 'text-blue-500 bg-blue-50'
    }
  ];

  // Alerts data
  const alerts = [
    {
      id: 1,
      type: 'warning',
      message: 'Vehicle ABC-123 maintenance due in 2 days',
      icon: FaExclamationTriangle,
      color: 'text-amber-500 bg-amber-50'
    },
    {
      id: 2,
      type: 'critical',
      message: 'Vehicle XYZ-789 fuel level critical',
      icon: FaGasPump,
      color: 'text-red-500 bg-red-50'
    }
  ];

  // Chart Data
  const vehicleUsageData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Active Vehicles',
        data: [18, 22, 19, 23, 20, 15, 17],
        backgroundColor: 'rgba(37, 99, 235, 0.2)',
        borderColor: 'rgb(37, 99, 235)',
        borderWidth: 2,
        borderRadius: 4,
        barThickness: 16,
        maxBarThickness: 20
      }
    ]
  };

  const fuelConsumptionData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Fuel Usage (L)',
        data: [420, 380, 450, 400, 480, 440],
        fill: true,
        backgroundColor: 'rgba(79, 70, 229, 0.1)',
        borderColor: 'rgb(79, 70, 229)',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: 'white',
        pointBorderColor: 'rgb(79, 70, 229)',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3
      }
    ]
  };

  // Chart Options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'white',
        titleColor: '#111827',
        bodyColor: '#111827',
        borderColor: '#E5E7EB',
        borderWidth: 1,
        padding: 12,
        boxPadding: 4,
        usePointStyle: true,
        titleFont: {
          size: 14,
          weight: 'bold'
        },
        bodyFont: {
          size: 13
        },
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: '#6B7280'
        }
      },
      y: {
        grid: {
          borderDash: [2, 4],
          drawBorder: false,
          color: '#E5E7EB'
        },
        ticks: {
          color: '#6B7280',
          padding: 8
        }
      }
    }
  };

  return (
    <Layout>
      <div className="min-h-screen bg-gray-50">
        <div className="p-6 max-w-7xl mx-auto">
          {/* Page Header */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
              <p className="text-sm text-gray-500 mt-1">Overview of your fleet management system</p>
            </div>
            <div className="flex items-center space-x-2">
              <FaCalendar className="text-gray-400" />
              <select 
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
                className="text-sm border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="quarter">This Quarter</option>
                <option value="year">This Year</option>
              </select>
            </div>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            {stats.map((stat, index) => (
              <div 
                key={index} 
                className={`bg-white rounded-xl shadow-sm border ${stat.borderColor} p-6 
                  hover:shadow-md transition-all duration-200`}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-600">{stat.title}</p>
                    <h3 className="text-2xl font-semibold text-gray-900 mt-1">{stat.value}</h3>
                  </div>
                  <div className={`p-3 rounded-lg ${stat.bgColor}`}>
                    <stat.icon className={`h-6 w-6 ${stat.iconColor}`} />
                  </div>
                </div>
                <div className="flex items-center mt-4 text-sm">
                  {stat.isIncrease ? (
                    <FaArrowUp className="text-green-600 mr-1" size={12} />
                  ) : (
                    <FaArrowDown className="text-red-600 mr-1" size={12} />
                  )}
                  <span className={stat.isIncrease ? 'text-green-600' : 'text-red-600'}>
                    {stat.change}
                  </span>
                  <span className="text-gray-500 ml-1 text-xs">from last period</span>
                </div>
              </div>
            ))}
          </div>

          {/* Charts Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            {/* Vehicle Usage Chart */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
              hover:shadow-md transition-all duration-200">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">Vehicle Usage</h3>
                  <p className="text-sm text-gray-500 mt-1">Daily vehicle activity</p>
                </div>
                <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
                  View Details
                </button>
              </div>
              <div className="h-[300px]">
                <Bar data={vehicleUsageData} options={chartOptions} />
              </div>
            </div>

            {/* Fuel Consumption Chart */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
              hover:shadow-md transition-all duration-200">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">Fuel Consumption</h3>
                  <p className="text-sm text-gray-500 mt-1">Monthly fuel usage trends</p>
                </div>
                <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
                  View Details
                </button>
              </div>
              <div className="h-[300px]">
                <Line data={fuelConsumptionData} options={chartOptions} />
              </div>
            </div>
          </div>

          {/* Bottom Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Recent Activities */}
            <div className="lg:col-span-2">
              <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
                hover:shadow-md transition-all duration-200">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">Recent Activities</h3>
                    <p className="text-sm text-gray-500 mt-1">Latest fleet activities</p>
                  </div>
                  <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
                    View All
                  </button>
                </div>
                <div className="space-y-6">
                  {activities.map((activity) => (
                    <div key={activity.id} className="flex items-start space-x-4">
                      <div className={`p-2 rounded-lg ${activity.color}`}>
                        <activity.icon className="h-5 w-5" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900">{activity.title}</p>
                        <p className="text-sm text-gray-500 mt-1">{activity.description}</p>
                        <p className="text-xs text-gray-400 mt-1">{activity.time}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Alerts Section */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
              hover:shadow-md transition-all duration-200">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">Active Alerts</h3>
                  <p className="text-sm text-gray-500 mt-1">System notifications</p>
                </div>
                <button className="text-sm text-blue-600 hover:text-blue-700 font-medium">
                  View All
                </button>
              </div>
              <div className="space-y-4">
                {alerts.map((alert) => (
                  <div key={alert.id} className="flex items-start space-x-3">
                    <div className={`p-2 rounded-lg ${alert.color}`}>
                      <alert.icon className="h-5 w-5" />
                    </div>
                    <div className="flex-1">
                      <p className="text-sm text-gray-900">{alert.message}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
