import React, { useState, useEffect } from 'react';
import { 
  FaHome, FaChevronRight, FaUserPlus, FaSearch, 
  FaFilter, FaEllipsisV, FaPhone, FaEnvelope,
  FaIdCard, FaUserAlt, FaEdit, FaTrash, FaTimes,
  FaCarAlt, FaCalendarAlt, FaMapMarkerAlt, FaPlus
} from 'react-icons/fa';
import Layout from '../components/Layout';
import ViewToggle from '../components/ViewToggle';

const Drivers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [view, setView] = useState('grid');

  const [drivers, setDrivers] = useState([
    {
      id: 1,
      name: 'John Smith',
      photo: null,
      status: 'active',
      phone: '+1 234-567-8901',
      email: 'john.smith@example.com',
      license: 'DL-123456',
      experience: '5 years',
      assignedVehicle: 'Toyota Camry (ABC-123)',
      address: '123 Main St, City',
      joinDate: '2020-03-15',
      rating: 4.8,
      completedTrips: 1250
    },
    {
      id: 2,
      name: 'Sarah Wilson',
      photo: null,
      status: 'on_trip',
      phone: '+1 234-567-8902',
      email: 'sarah.wilson@example.com',
      license: 'DL-789012',
      experience: '3 years',
      assignedVehicle: 'Ford Transit (XYZ-789)',
      address: '456 Oak Ave, Town',
      joinDate: '2021-06-20',
      rating: 4.9,
      completedTrips: 850
    },
    {
      id: 3,
      name: 'Mike Johnson',
      photo: null,
      status: 'inactive',
      phone: '+1 234-567-8903',
      email: 'mike.johnson@example.com',
      license: 'DL-345678',
      experience: '2 years',
      assignedVehicle: 'Honda CR-V (DEF-456)',
      address: '789 Pine Rd, Village',
      joinDate: '2022-01-10',
      rating: 4.6,
      completedTrips: 520
    }
  ]);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.dropdown-container')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    license: '',
    experience: '',
    assignedVehicle: '',
    address: '',
    status: 'active'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getStatusColor = (status) => {
    const colors = {
      active: 'text-green-600 bg-green-50 border-green-100',
      on_trip: 'text-blue-600 bg-blue-50 border-blue-100',
      inactive: 'text-red-600 bg-red-50 border-red-100'
    };
    return colors[status] || colors.active;
  };

  const getStatusLabel = (status) => {
    const labels = {
      active: 'Available',
      on_trip: 'On Trip',
      inactive: 'Inactive'
    };
    return labels[status] || status;
  };

  const getRatingColor = (rating) => {
    if (rating >= 4.5) return 'text-green-600';
    if (rating >= 4.0) return 'text-yellow-600';
    return 'text-red-600';
  };

  const handleAddDriver = (e) => {
    e.preventDefault();
    const newDriver = {
      id: drivers.length + 1,
      ...formData,
      photo: null,
      joinDate: new Date().toISOString().split('T')[0],
      rating: 5.0,
      completedTrips: 0
    };
    setDrivers(prev => [...prev, newDriver]);
    setIsAddModalOpen(false);
    setFormData({
      name: '',
      phone: '',
      email: '',
      license: '',
      experience: '',
      assignedVehicle: '',
      address: '',
      status: 'active'
    });
  };

  const handleEditDriver = (e) => {
    e.preventDefault();
    setDrivers(prev => prev.map(driver => 
      driver.id === selectedDriver.id ? { ...driver, ...formData } : driver
    ));
    setIsEditModalOpen(false);
    setSelectedDriver(null);
  };

  const handleDeleteDriver = () => {
    setDrivers(prev => prev.filter(driver => driver.id !== selectedDriver.id));
    setIsDeleteModalOpen(false);
    setSelectedDriver(null);
  };

  const openEditModal = (driver) => {
    setSelectedDriver(driver);
    setFormData(driver);
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (driver) => {
    setSelectedDriver(driver);
    setIsDeleteModalOpen(true);
  };

  const filteredDrivers = drivers.filter(driver => {
    const matchesSearch = driver.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         driver.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         driver.phone.includes(searchTerm);
    const matchesFilter = filterStatus === 'all' || driver.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  // Modal Component
  const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-xl mx-4 h-[90vh] flex flex-col">
          {/* Header */}
          <div className="flex items-center justify-between px-4 sm:px-6 py-4 border-b border-gray-200 flex-shrink-0">
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <FaTimes className="text-gray-500" />
            </button>
          </div>
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4 sm:p-6">
            {children}
          </div>
        </div>
      </div>
    );
  };

  // Driver Form Component
  const DriverForm = ({ onSubmit, buttonText }) => (
    <form onSubmit={onSubmit} className="space-y-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Full Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            License Number
          </label>
          <input
            type="text"
            name="license"
            value={formData.license}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Experience
          </label>
          <input
            type="text"
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Assigned Vehicle
          </label>
          <input
            type="text"
            name="assignedVehicle"
            value={formData.assignedVehicle}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
      </div>

      <div className="col-span-full">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Address
        </label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
            focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>

      {/* Footer Buttons */}
      <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0 sm:space-x-3 pt-4">
        <button
          type="button"
          onClick={() => {
            setIsAddModalOpen(false);
            setIsEditModalOpen(false);
          }}
          className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-gray-100 rounded-lg 
            hover:bg-gray-200 transition-colors focus:outline-none focus:ring-2 
            focus:ring-gray-500 focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg 
            hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 
            focus:ring-blue-500 focus:ring-offset-2"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
      

        {/* Main Content */}
        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 p-4 sm:p-6">
            <div className="flex flex-col space-y-4">
              {/* Title and Add Button */}
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 text-center sm:text-left">
                <div>
                  <h1 className="text-2xl font-semibold text-[#262626]">Drivers</h1>
                  <p className="text-sm text-gray-500 mt-1">Manage your fleet drivers</p>
                </div>
                <button 
                  onClick={() => setIsAddModalOpen(true)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-5 py-2.5 
                    bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                    shadow-sm font-medium"
                >
                  <FaPlus className="mr-2" size={16} />
                  Add Driver
                </button>
              </div>

              {/* Filters Section */}
              <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center gap-4">
                {/* Search */}
                <div className="flex-1 relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search drivers..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      bg-white shadow-sm"
                  />
                </div>

                {/* Status Filter and View Toggle */}
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
                  <div className="w-full sm:w-auto">
                    <div className="flex items-center space-x-2 bg-white px-3 py-2 border 
                      border-gray-300 rounded-lg shadow-sm w-full sm:w-auto">
                      <FaFilter className="text-gray-400" />
                      <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="w-full sm:w-auto border-0 focus:outline-none focus:ring-0 
                          text-sm text-gray-600 bg-transparent pr-8"
                      >
                        <option value="all">All Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <ViewToggle view={view} onViewChange={setView} />
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          {view === 'grid' ? (
            // Existing grid view remains the same
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredDrivers.map((driver) => (
                <div 
                  key={driver.id}
                  className="bg-white rounded-lg shadow-sm border border-gray-200/60 hover:shadow-md 
                    transition-all duration-200 overflow-hidden group"
                >
                  <div className="p-6">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-4">
                      <div className="flex items-center space-x-3">
                        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-blue-600 font-medium text-lg">
                            {driver.name.split(' ').map(n => n[0]).join('')}
                          </span>
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold text-[#262626] group-hover:text-blue-600 
                            transition-colors">
                            {driver.name}
                          </h3>
                          <span className={`
                            px-2.5 py-1 rounded-full text-xs font-medium border inline-block mt-1
                            ${getStatusColor(driver.status)}
                          `}>
                            {getStatusLabel(driver.status)}
                          </span>
                        </div>
                      </div>
                      <div className="relative dropdown-container">
                        <button 
                          onClick={() => setActiveDropdown(activeDropdown === driver.id ? null : driver.id)}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <FaEllipsisV className="text-gray-400" size={16} />
                        </button>
                        {activeDropdown === driver.id && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
                            border border-gray-200 py-1 z-10">
                            <button
                              onClick={() => {
                                openEditModal(driver);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-gray-700 
                                hover:bg-gray-100 flex items-center"
                            >
                              <FaEdit className="mr-2" size={14} />
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                openDeleteModal(driver);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-red-600 
                                hover:bg-red-50 flex items-center"
                            >
                              <FaTrash className="mr-2" size={14} />
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Details */}
                    <div className="space-y-3">
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaPhone className="mr-2 text-gray-400" size={14} />
                        {driver.phone}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaEnvelope className="mr-2 text-gray-400" size={14} />
                        {driver.email}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaIdCard className="mr-2 text-gray-400" size={14} />
                        License: {driver.license}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaCarAlt className="mr-2 text-gray-400" size={14} />
                        {driver.assignedVehicle}
                      </div>
                    </div>

                    {/* Stats */}
                    <div className="mt-4 pt-4 border-t border-gray-100">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className={`text-lg font-semibold ${getRatingColor(driver.rating)}`}>
                            {driver.rating}
                          </div>
                          <div className="text-sm text-gray-500 ml-2">Rating</div>
                        </div>
                        <div className="text-sm text-gray-500">
                          {driver.completedTrips} trips completed
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom border */}
                  <div className="h-1 bg-blue-500 transform origin-left scale-x-0 
                    group-hover:scale-x-100 transition-transform duration-300" />
                </div>
              ))}
            </div>
          ) : (
            // New table view
            <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Driver
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Contact
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        License
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Vehicle
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rating
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredDrivers.map((driver) => (
                      <tr 
                        key={driver.id}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                              <span className="text-blue-600 font-medium text-sm">
                                {driver.name.split(' ').map(n => n[0]).join('')}
                              </span>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {driver.name}
                              </div>
                              <div className="text-xs text-gray-500">
                                Joined {driver.joinDate}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`
                            px-2.5 py-1 rounded-full text-xs font-medium border
                            ${getStatusColor(driver.status)}
                          `}>
                            {getStatusLabel(driver.status)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{driver.phone}</div>
                          <div className="text-xs text-gray-500">{driver.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {driver.license}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {driver.assignedVehicle}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <span className={`text-sm font-medium ${getRatingColor(driver.rating)}`}>
                              {driver.rating}
                            </span>
                            <span className="text-xs text-gray-500 ml-2">
                              ({driver.completedTrips} trips)
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-2">
                            <button
                              onClick={() => openEditModal(driver)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <FaEdit size={16} />
                            </button>
                            <button
                              onClick={() => openDeleteModal(driver)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <FaTrash size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add New Driver"
        className="overflow-y-auto h-90"
      >
        <DriverForm onSubmit={handleAddDriver} buttonText="Add Driver" />
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Driver"
      >
        <DriverForm onSubmit={handleEditDriver} buttonText="Save Changes" />
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Driver"
      >
        <div className="space-y-4">
          <p className="text-gray-700">
            Are you sure you want to delete this driver? This action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteDriver}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 
                transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Drivers;
