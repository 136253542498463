import React, { useState } from 'react';
import { 
  FaHome, FaChevronRight, FaCog, FaBell, FaGlobe, 
  FaPalette, FaShieldAlt, FaDatabase, FaToggleOn, 
  FaToggleOff, FaMoon, FaSun
} from 'react-icons/fa';
import Layout from '../components/Layout';

const Settings = () => {
  const [settings, setSettings] = useState({
    theme: 'light',
    language: 'en',
    notifications: {
      email: true,
      push: true,
      sms: false
    },
    security: {
      twoFactor: false,
      loginAlerts: true
    },
    display: {
      compactMode: false,
      darkMode: false
    }
  });

  const handleToggle = (category, setting) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [setting]: !prev[category][setting]
      }
    }));
  };

  const handleThemeChange = (theme) => {
    setSettings(prev => ({
      ...prev,
      theme
    }));
  };

  const handleLanguageChange = (e) => {
    setSettings(prev => ({
      ...prev,
      language: e.target.value
    }));
  };

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
       

        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* General Settings */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">General Settings</h2>
            
            {/* Theme Selection */}
            <div className="mb-8">
              <h3 className="text-sm font-medium text-gray-700 mb-4">Theme</h3>
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={() => handleThemeChange('light')}
                  className={`p-4 rounded-lg border flex items-center space-x-3
                    ${settings.theme === 'light' 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:border-blue-500'}`}
                >
                  <FaSun className={settings.theme === 'light' ? 'text-blue-500' : 'text-gray-400'} size={20} />
                  <span className={settings.theme === 'light' ? 'text-blue-600' : 'text-gray-600'}>Light</span>
                </button>
                <button
                  onClick={() => handleThemeChange('dark')}
                  className={`p-4 rounded-lg border flex items-center space-x-3
                    ${settings.theme === 'dark' 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:border-blue-500'}`}
                >
                  <FaMoon className={settings.theme === 'dark' ? 'text-blue-500' : 'text-gray-400'} size={20} />
                  <span className={settings.theme === 'dark' ? 'text-blue-600' : 'text-gray-600'}>Dark</span>
                </button>
              </div>
            </div>

            {/* Language Selection */}
            <div className="mb-8">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Language</h3>
              <select
                value={settings.language}
                onChange={handleLanguageChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 
                  focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-lg"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
              </select>
            </div>

            {/* Display Settings */}
            <div className="space-y-4">
              <h3 className="text-sm font-medium text-gray-700">Display</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-900">Compact Mode</p>
                    <p className="text-sm text-gray-500">Reduce spacing and padding</p>
                  </div>
                  <button
                    onClick={() => handleToggle('display', 'compactMode')}
                    className="text-2xl"
                  >
                    {settings.display.compactMode ? (
                      <FaToggleOn className="text-blue-500" />
                    ) : (
                      <FaToggleOff className="text-gray-300" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Notification Settings */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Notifications</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">Email Notifications</p>
                  <p className="text-sm text-gray-500">Receive email updates</p>
                </div>
                <button
                  onClick={() => handleToggle('notifications', 'email')}
                  className="text-2xl"
                >
                  {settings.notifications.email ? (
                    <FaToggleOn className="text-blue-500" />
                  ) : (
                    <FaToggleOff className="text-gray-300" />
                  )}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">Push Notifications</p>
                  <p className="text-sm text-gray-500">Receive push notifications</p>
                </div>
                <button
                  onClick={() => handleToggle('notifications', 'push')}
                  className="text-2xl"
                >
                  {settings.notifications.push ? (
                    <FaToggleOn className="text-blue-500" />
                  ) : (
                    <FaToggleOff className="text-gray-300" />
                  )}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">SMS Notifications</p>
                  <p className="text-sm text-gray-500">Receive SMS alerts</p>
                </div>
                <button
                  onClick={() => handleToggle('notifications', 'sms')}
                  className="text-2xl"
                >
                  {settings.notifications.sms ? (
                    <FaToggleOn className="text-blue-500" />
                  ) : (
                    <FaToggleOff className="text-gray-300" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Security Settings */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Security</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">Two-Factor Authentication</p>
                  <p className="text-sm text-gray-500">Add an extra layer of security</p>
                </div>
                <button
                  onClick={() => handleToggle('security', 'twoFactor')}
                  className="text-2xl"
                >
                  {settings.security.twoFactor ? (
                    <FaToggleOn className="text-blue-500" />
                  ) : (
                    <FaToggleOff className="text-gray-300" />
                  )}
                </button>
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">Login Alerts</p>
                  <p className="text-sm text-gray-500">Get notified of new logins</p>
                </div>
                <button
                  onClick={() => handleToggle('security', 'loginAlerts')}
                  className="text-2xl"
                >
                  {settings.security.loginAlerts ? (
                    <FaToggleOn className="text-blue-500" />
                  ) : (
                    <FaToggleOff className="text-gray-300" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings; 