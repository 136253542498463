import React, { useState, useEffect } from 'react';
import { 
  FaHome, FaChevronRight, FaPlus, FaSearch, 
  FaFilter, FaEllipsisV, FaTools, FaCar,
  FaCalendarAlt, FaUserAlt, FaEdit, FaTrash,
  FaTimes, FaClipboardList, FaMoneyBillWave,
  FaExclamationTriangle, FaCheckCircle
} from 'react-icons/fa';
import Layout from '../components/Layout';
import ViewToggle from '../components/ViewToggle';

const Maintenance = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [view, setView] = useState('grid');

  const [maintenanceRecords, setMaintenanceRecords] = useState([
    {
      id: 1,
      vehicle: 'Toyota Camry (ABC-123)',
      type: 'Scheduled Service',
      description: 'Regular maintenance check and oil change',
      status: 'completed',
      date: '2024-02-15',
      technician: 'John Smith',
      cost: 350.00,
      nextDueDate: '2024-05-15',
      priority: 'normal',
      notes: 'All systems checked, replaced oil filter'
    },
    {
      id: 2,
      vehicle: 'Ford Transit (XYZ-789)',
      type: 'Repair',
      description: 'Brake system repair',
      status: 'in_progress',
      date: '2024-02-20',
      technician: 'Mike Johnson',
      cost: 850.00,
      nextDueDate: null,
      priority: 'high',
      notes: 'Replacing brake pads and rotors'
    },
    {
      id: 3,
      vehicle: 'Honda CR-V (DEF-456)',
      type: 'Inspection',
      description: 'Annual vehicle inspection',
      status: 'pending',
      date: '2024-02-25',
      technician: 'Sarah Wilson',
      cost: 150.00,
      nextDueDate: null,
      priority: 'low',
      notes: 'Scheduled for routine inspection'
    }
  ]);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.dropdown-container')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  // Form state
  const [formData, setFormData] = useState({
    vehicle: '',
    type: 'Scheduled Service',
    description: '',
    status: 'pending',
    date: '',
    technician: '',
    cost: '',
    nextDueDate: '',
    priority: 'normal',
    notes: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getStatusColor = (status) => {
    const colors = {
      completed: 'text-green-600 bg-green-50 border-green-100',
      in_progress: 'text-blue-600 bg-blue-50 border-blue-100',
      pending: 'text-yellow-600 bg-yellow-50 border-yellow-100',
      cancelled: 'text-red-600 bg-red-50 border-red-100'
    };
    return colors[status] || colors.pending;
  };

  const getPriorityColor = (priority) => {
    const colors = {
      high: 'text-red-600',
      normal: 'text-blue-600',
      low: 'text-green-600'
    };
    return colors[priority] || colors.normal;
  };

  const getStatusLabel = (status) => {
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  const handleAddRecord = (e) => {
    e.preventDefault();
    const newRecord = {
      id: maintenanceRecords.length + 1,
      ...formData,
      cost: parseFloat(formData.cost)
    };
    setMaintenanceRecords(prev => [...prev, newRecord]);
    setIsAddModalOpen(false);
    setFormData({
      vehicle: '',
      type: 'Scheduled Service',
      description: '',
      status: 'pending',
      date: '',
      technician: '',
      cost: '',
      nextDueDate: '',
      priority: 'normal',
      notes: ''
    });
  };

  const handleEditRecord = (e) => {
    e.preventDefault();
    setMaintenanceRecords(prev => prev.map(record => 
      record.id === selectedRecord.id ? { 
        ...record, 
        ...formData,
        cost: parseFloat(formData.cost)
      } : record
    ));
    setIsEditModalOpen(false);
    setSelectedRecord(null);
  };

  const handleDeleteRecord = () => {
    setMaintenanceRecords(prev => 
      prev.filter(record => record.id !== selectedRecord.id)
    );
    setIsDeleteModalOpen(false);
    setSelectedRecord(null);
  };

  const openEditModal = (record) => {
    setSelectedRecord(record);
    setFormData({
      ...record,
      cost: record.cost.toString()
    });
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (record) => {
    setSelectedRecord(record);
    setIsDeleteModalOpen(true);
  };

  const filteredRecords = maintenanceRecords.filter(record => {
    const matchesSearch = 
      record.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      record.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      record.technician.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filterStatus === 'all' || record.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  // Modal Component
  const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl w-full mx-4 max-w-xl h-[90vh] flex flex-col">
          {/* Header */}
          <div className="flex items-center justify-between px-4 sm:px-6 py-4 border-b border-gray-200 flex-shrink-0">
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <FaTimes className="text-gray-500" />
            </button>
          </div>
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    );
  };

  // Maintenance Form Component
  const MaintenanceForm = ({ onSubmit, buttonText }) => (
    <form onSubmit={onSubmit} className="p-4 sm:p-6 space-y-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">Vehicle</label>
          <input
            type="text"
            name="vehicle"
            value={formData.vehicle}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div className="col-span-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="Scheduled Service">Scheduled Service</option>
            <option value="Repair">Repair</option>
            <option value="Inspection">Inspection</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="col-span-1 sm:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="pending">Pending</option>
            <option value="in_progress">In Progress</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Priority</label>
          <select
            name="priority"
            value={formData.priority}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="low">Low</option>
            <option value="normal">Normal</option>
            <option value="high">High</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Next Due Date</label>
          <input
            type="date"
            name="nextDueDate"
            value={formData.nextDueDate || ''}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Technician</label>
          <input
            type="text"
            name="technician"
            value={formData.technician}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Cost ($)</label>
          <input
            type="number"
            name="cost"
            value={formData.cost}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
            min="0"
            step="0.01"
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleInputChange}
            rows="3"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none 
              focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {/* Footer Buttons */}
      <div className="flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0 sm:space-x-3 pt-4 
        sticky bottom-0 bg-white border-t mt-4 -mx-4 sm:-mx-6 px-4 sm:px-6 py-3"
      >
        <button
          type="button"
          onClick={() => {
            setIsAddModalOpen(false);
            setIsEditModalOpen(false);
          }}
          className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-gray-100 rounded-lg 
            hover:bg-gray-200 transition-colors"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg 
            hover:bg-blue-700 transition-colors"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
        

        {/* Main Content */}
        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 p-4 sm:p-6">
            <div className="flex flex-col space-y-4">
              {/* Title and Add Button */}
              <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 text-center sm:text-left">
                <div>
                  <h1 className="text-2xl font-semibold text-[#262626]">Maintenance Records</h1>
                  <p className="text-sm text-gray-500 mt-1">Manage vehicle maintenance and repairs</p>
                </div>
                <button 
                  onClick={() => setIsAddModalOpen(true)}
                  className="w-full sm:w-auto inline-flex items-center justify-center px-5 py-2.5 
                    bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors 
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                    shadow-sm font-medium"
                >
                  <FaPlus className="mr-2" size={16} />
                  Add Record
                </button>
              </div>

              {/* Filters Section */}
              <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center gap-4">
                {/* Search */}
                <div className="flex-1 relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search records..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      bg-white shadow-sm"
                  />
                </div>

                {/* Status Filter and View Toggle */}
                <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
                  <div className="w-full sm:w-auto">
                    <div className="flex items-center space-x-2 bg-white px-3 py-2 border 
                      border-gray-300 rounded-lg shadow-sm w-full sm:w-auto">
                      <FaFilter className="text-gray-400" />
                      <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="w-full sm:w-auto border-0 focus:outline-none focus:ring-0 
                          text-sm text-gray-600 bg-transparent pr-8"
                      >
                        <option value="all">All Status</option>
                        <option value="pending">Pending</option>
                        <option value="in_progress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  <ViewToggle view={view} onViewChange={setView} />
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          {view === 'grid' ? (
            // Existing grid view
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredRecords.map((record) => (
                <div 
                  key={record.id}
                  className="bg-white rounded-lg shadow-sm border border-gray-200/60 hover:shadow-md 
                    transition-all duration-200 overflow-hidden group"
                >
                  <div className="p-6">
                    {/* Header */}
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <div className="flex items-center space-x-2">
                          <FaCar className="text-gray-400" size={18} />
                          <h3 className="text-lg font-semibold text-[#262626] group-hover:text-blue-600 
                            transition-colors">
                            {record.vehicle}
                          </h3>
                        </div>
                        <div className="flex items-center space-x-2 mt-2">
                          <span className={`
                            px-2.5 py-1 rounded-full text-xs font-medium border
                            ${getStatusColor(record.status)}
                          `}>
                            {getStatusLabel(record.status)}
                          </span>
                          <span className={`
                            text-xs font-medium
                            ${getPriorityColor(record.priority)}
                          `}>
                            {record.priority.charAt(0).toUpperCase() + record.priority.slice(1)} Priority
                          </span>
                        </div>
                      </div>
                      <div className="relative dropdown-container">
                        <button 
                          onClick={() => setActiveDropdown(activeDropdown === record.id ? null : record.id)}
                          className="p-1 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <FaEllipsisV className="text-gray-400" size={16} />
                        </button>
                        {activeDropdown === record.id && (
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg 
                            border border-gray-200 py-1 z-10">
                            <button
                              onClick={() => {
                                openEditModal(record);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-gray-700 
                                hover:bg-gray-100 flex items-center"
                            >
                              <FaEdit className="mr-2" size={14} />
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                openDeleteModal(record);
                                setActiveDropdown(null);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-red-600 
                                hover:bg-red-50 flex items-center"
                            >
                              <FaTrash className="mr-2" size={14} />
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Details */}
                    <div className="space-y-3">
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaClipboardList className="mr-2 text-gray-400" size={14} />
                        {record.type}: {record.description}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaUserAlt className="mr-2 text-gray-400" size={14} />
                        Technician: {record.technician}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaCalendarAlt className="mr-2 text-gray-400" size={14} />
                        Date: {record.date}
                      </div>
                      <div className="flex items-center text-sm text-[#595959]">
                        <FaMoneyBillWave className="mr-2 text-gray-400" size={14} />
                        Cost: ${record.cost.toFixed(2)}
                      </div>
                    </div>

                    {/* Next Due Date */}
                    {record.nextDueDate && (
                      <div className="mt-4 pt-4 border-t border-gray-100">
                        <div className="flex items-center text-sm">
                          <FaExclamationTriangle className="mr-2 text-yellow-500" size={14} />
                          <span className="text-gray-600">
                            Next service due: {record.nextDueDate}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Bottom border */}
                  <div className="h-1 bg-blue-500 transform origin-left scale-x-0 
                    group-hover:scale-x-100 transition-transform duration-300" />
                </div>
              ))}
            </div>
          ) : (
            // New table view
            <div className="bg-white rounded-lg shadow-sm border border-gray-200/60 overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Vehicle
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Technician
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cost
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredRecords.map((record) => (
                      <tr 
                        key={record.id}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <FaCar className="text-gray-400 mr-2" size={16} />
                            <div className="text-sm font-medium text-gray-900">
                              {record.vehicle}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{record.type}</div>
                          <div className="text-xs text-gray-500">{record.description}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`
                            px-2.5 py-1 rounded-full text-xs font-medium border
                            ${getStatusColor(record.status)}
                          `}>
                            {getStatusLabel(record.status)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {record.date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {record.technician}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          ${record.cost.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-2">
                            <button
                              onClick={() => openEditModal(record)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <FaEdit size={16} />
                            </button>
                            <button
                              onClick={() => openDeleteModal(record)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <FaTrash size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        title="Add Maintenance Record"
      >
        <MaintenanceForm onSubmit={handleAddRecord} buttonText="Add Record" />
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        title="Edit Maintenance Record"
      >
        <MaintenanceForm onSubmit={handleEditRecord} buttonText="Save Changes" />
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Maintenance Record"
      >
        <div className="space-y-4">
          <p className="text-gray-700">
            Are you sure you want to delete this maintenance record? This action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteRecord}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 
                transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Maintenance;
