// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Vehicles from './pages/Vehicles';
import Maintenance from './pages/Maintenance';
import Drivers from './pages/Drivers';
import Settings from './pages/Settings';
import NotFound from './pages/NotFound';
import FuelManagement from './pages/FuelManagement';
import Reports from './pages/Reports';
import Notifications from './pages/Notifications';
import Integrations from './pages/Integrations';
import Profile from './pages/Profile';
import Users from './pages/Users';
import './styles/scrollbar.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/fuel" element={<FuelManagement />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/users" element={<Users />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
