import React, { useState } from 'react';
import { 
  FaHome, FaChevronRight, FaUser, FaCamera, 
  FaEnvelope, FaPhone, FaLock, FaShieldAlt,
  FaBell, FaHistory
} from 'react-icons/fa';
import Layout from '../components/Layout';

const Profile = () => {
  const [activeTab, setActiveTab] = useState('general');
  const [profileData, setProfileData] = useState({
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    phone: '+1 234-567-8901',
    role: 'Administrator',
    joinDate: '2023-01-15',
    avatar: null
  });

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(profileData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProfileData(formData);
    setIsEditing(false);
  };

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
     

        <div className="p-6 max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Profile Card */}
            <div className="lg:col-span-1">
              <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
                <div className="flex flex-col items-center">
                  <div className="relative">
                    <div className="w-24 h-24 rounded-full bg-blue-600 flex items-center justify-center text-white text-2xl font-semibold">
                      {profileData.firstName[0]}{profileData.lastName[0]}
                    </div>
                    <button className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-shadow">
                      <FaCamera className="text-gray-600" size={16} />
                    </button>
                  </div>
                  <h2 className="mt-4 text-xl font-semibold text-gray-900">
                    {profileData.firstName} {profileData.lastName}
                  </h2>
                  <p className="text-sm text-gray-500">{profileData.role}</p>
                  <div className="mt-4 w-full">
                    <div className="flex items-center justify-between py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-500">Member since</span>
                      <span className="text-sm font-medium">{profileData.joinDate}</span>
                    </div>
                    <div className="flex items-center justify-between py-2">
                      <span className="text-sm text-gray-500">Status</span>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Active
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="lg:col-span-2">
              <div className="bg-white rounded-xl shadow-sm border border-gray-200/60">
                {/* Tabs */}
                <div className="border-b border-gray-200">
                  <div className="flex space-x-6 px-6">
                    <button
                      onClick={() => setActiveTab('general')}
                      className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors
                        ${activeTab === 'general' 
                          ? 'border-blue-600 text-blue-600' 
                          : 'border-transparent text-gray-500 hover:text-gray-700'}`}
                    >
                      General
                    </button>
                    <button
                      onClick={() => setActiveTab('security')}
                      className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors
                        ${activeTab === 'security' 
                          ? 'border-blue-600 text-blue-600' 
                          : 'border-transparent text-gray-500 hover:text-gray-700'}`}
                    >
                      Security
                    </button>
                    <button
                      onClick={() => setActiveTab('notifications')}
                      className={`py-4 px-2 text-sm font-medium border-b-2 transition-colors
                        ${activeTab === 'notifications' 
                          ? 'border-blue-600 text-blue-600' 
                          : 'border-transparent text-gray-500 hover:text-gray-700'}`}
                    >
                      Notifications
                    </button>
                  </div>
                </div>

                {/* Tab Content */}
                <div className="p-6">
                  {activeTab === 'general' && (
                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className="mt-1 block w-full rounded-lg border border-gray-300 
                              shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className="mt-1 block w-full rounded-lg border border-gray-300 
                              shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          disabled={!isEditing}
                          className="mt-1 block w-full rounded-lg border border-gray-300 
                            shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                            focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          disabled={!isEditing}
                          className="mt-1 block w-full rounded-lg border border-gray-300 
                            shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                            focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>

                      <div className="flex justify-end space-x-3">
                        {isEditing ? (
                          <>
                            <button
                              type="button"
                              onClick={() => setIsEditing(false)}
                              className="px-4 py-2 text-sm font-medium text-gray-700 
                                hover:text-gray-800"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="px-4 py-2 bg-blue-600 text-white text-sm font-medium 
                                rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 
                                focus:ring-blue-500 focus:ring-offset-2"
                            >
                              Save Changes
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setIsEditing(true)}
                            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium 
                              rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Edit Profile
                          </button>
                        )}
                      </div>
                    </form>
                  )}

                  {activeTab === 'security' && (
                    <div className="space-y-6">
                      {/* Password Change Section */}
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Ensure your account is using a long, random password to stay secure.
                        </p>
                      </div>

                      <form className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Current Password
                          </label>
                          <input
                            type="password"
                            className="mt-1 block w-full rounded-lg border border-gray-300 
                              shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            New Password
                          </label>
                          <input
                            type="password"
                            className="mt-1 block w-full rounded-lg border border-gray-300 
                              shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            className="mt-1 block w-full rounded-lg border border-gray-300 
                              shadow-sm py-2 px-3 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                        <div className="flex justify-end">
                          <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium 
                              rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 
                              focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Update Password
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {activeTab === 'notifications' && (
                    <div className="space-y-6">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">Notification Preferences</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Manage how you receive notifications and alerts.
                        </p>
                      </div>

                      <div className="space-y-4">
                        {/* Add notification preferences toggles here */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile; 