import React, { useState } from 'react';
import { 
  FaHome, FaChevronRight, FaMapMarkedAlt, FaSatellite, 
  FaPlug, FaCheck, FaCog, FaExternalLinkAlt, FaKey,
  FaGoogle, FaMap, FaCarAlt, FaChartLine
} from 'react-icons/fa';
import Layout from '../components/Layout';

const Integrations = () => {
  const [integrations, setIntegrations] = useState({
    googleMaps: {
      name: 'Google Maps',
      icon: FaGoogle,
      status: 'connected',
      apiKey: '****************************************',
      description: 'Real-time vehicle tracking and route optimization',
      features: [
        'Live vehicle tracking',
        'Route optimization',
        'Geofencing',
        'Traffic data'
      ]
    },
    mapbox: {
      name: 'Mapbox',
      icon: FaMap,
      status: 'disconnected',
      apiKey: '',
      description: 'Alternative mapping service with custom styling options',
      features: [
        'Custom map styles',
        'Turn-by-turn navigation',
        'Distance calculations',
        'Terrain data'
      ]
    },
    telematicsDevice1: {
      name: 'GPS Tracker Pro',
      icon: FaSatellite,
      status: 'connected',
      deviceId: 'DEV123456',
      description: 'GPS tracking device for real-time vehicle data',
      features: [
        'Location tracking',
        'Speed monitoring',
        'Fuel consumption',
        'Engine diagnostics'
      ]
    },
    telematicsDevice2: {
      name: 'FleetGuard GPS',
      icon: FaCarAlt,
      status: 'connected',
      deviceId: 'FG789012',
      description: 'Advanced telematics system for fleet management',
      features: [
        'Driver behavior',
        'Maintenance alerts',
        'Temperature monitoring',
        'Asset tracking'
      ]
    }
  });

  const handleConnect = (integrationKey) => {
    // Implementation for connecting to service
    console.log('Connecting to:', integrationKey);
  };

  const handleDisconnect = (integrationKey) => {
    // Implementation for disconnecting service
    console.log('Disconnecting:', integrationKey);
  };

  const handleConfigureAPI = (integrationKey) => {
    // Implementation for API configuration
    console.log('Configuring API for:', integrationKey);
  };

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
       

        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div>
                <h1 className="text-2xl font-semibold text-[#262626]">Integrations</h1>
                <p className="text-sm text-gray-500 mt-1">Manage your third-party service connections</p>
              </div>
            </div>
          </div>

          {/* Integration Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Object.entries(integrations).map(([key, integration]) => (
              <div 
                key={key}
                className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6 
                  hover:shadow-md transition-shadow duration-200"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="p-3 bg-blue-50 rounded-lg">
                      <integration.icon size={24} className="text-blue-500" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">{integration.name}</h3>
                      <span className={`
                        inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1
                        ${integration.status === 'connected' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-gray-100 text-gray-800'}
                      `}>
                        {integration.status === 'connected' ? (
                          <>
                            <FaCheck className="mr-1" size={10} />
                            Connected
                          </>
                        ) : 'Not Connected'}
                      </span>
                    </div>
                  </div>
                  <button 
                    onClick={() => handleConfigureAPI(key)}
                    className="p-2 text-gray-400 hover:text-gray-500 rounded-lg 
                      hover:bg-gray-100 transition-colors"
                  >
                    <FaCog size={16} />
                  </button>
                </div>

                <p className="text-sm text-gray-500 mt-4">
                  {integration.description}
                </p>

                <div className="mt-4 space-y-2">
                  {integration.features.map((feature, index) => (
                    <div key={index} className="flex items-center text-sm text-gray-600">
                      <FaCheck className="mr-2 text-green-500" size={12} />
                      {feature}
                    </div>
                  ))}
                </div>

                <div className="mt-6 flex items-center justify-between">
                  {integration.status === 'connected' ? (
                    <>
                      <div className="flex items-center space-x-2 text-sm">
                        <FaKey className="text-gray-400" size={14} />
                        <span className="font-mono text-gray-600">
                          {integration.apiKey || integration.deviceId}
                        </span>
                      </div>
                      <button
                        onClick={() => handleDisconnect(key)}
                        className="px-4 py-2 text-sm font-medium text-red-600 
                          hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        Disconnect
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => handleConnect(key)}
                      className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg 
                        hover:bg-blue-700 transition-colors flex items-center justify-center 
                        space-x-2"
                    >
                      <FaPlug size={16} />
                      <span>Connect</span>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Integrations; 