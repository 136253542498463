// src/components/Sidebar.js

import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  FaTachometerAlt, FaCar, FaUserFriends, FaTools,
  FaUser, FaSignOutAlt, FaChartBar, FaCog, FaHome, 
  FaChartLine, FaGasPump, FaPlug, FaUsers, FaTimes
} from 'react-icons/fa';

const Sidebar = ({ isCollapsed, onNavigate, onCloseMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleNavigation = (path) => {
    navigate(path);
    onNavigate?.();
    onCloseMobile?.();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const menuItems = [
    {
      type: 'main',
      items: [
        {
          name: 'Dashboard',
          path: '/dashboard',
          icon: <FaTachometerAlt size={20} />,
          badge: null
        },
        {
          name: 'Reports & Analytics',
          path: '/reports',
          icon: <FaChartBar size={20} />,
          badge: 'New'
        }
      ]
    },
    {
      type: 'fleet',
      label: 'Fleet Management',
      items: [
        {
          name: 'Users',
          path: '/users',
          icon: <FaUsers size={20} />,
          badge: null
        },
        {
          name: 'Vehicles',
          path: '/vehicles',
          icon: <FaCar size={20} />,
          badge: '12'
        },
        {
          name: 'Drivers',
          path: '/drivers',
          icon: <FaUserFriends size={20} />,
          badge: '8'
        },
        {
          name: 'Maintenance',
          path: '/maintenance',
          icon: <FaTools size={20} />,
          badge: '3'
        },
        {
          name: 'Fuel Management',
          path: '/fuel',
          icon: <FaGasPump size={20} />,
          badge: null
        },
      ]
    },
    {
      type: 'settings',
      label: 'Settings',
      items: [
        {
          name: 'Integrations',
          path: '/integrations',
          icon: <FaPlug size={20} />,
          badge: null
        }
      ]
    }
  ];

  const renderMenuSection = (section) => (
    <div key={section.type} className="mb-6">
      {section.label && !isCollapsed && (
        <h3 className="px-4 mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wider">
          {section.label}
        </h3>
      )}
      <div className="space-y-1">
        {section.items.map((item) => (
          <button
            key={item.path}
            onClick={() => handleNavigation(item.path)}
            className={`
              w-full flex items-center px-4 py-2.5 text-sm font-medium
              ${location.pathname === item.path
                ? 'text-white bg-blue-600'
                : 'text-gray-300 hover:text-white hover:bg-gray-800'
              }
              rounded-lg transition-colors duration-200
              ${isCollapsed ? 'justify-center' : ''}
            `}
            title={isCollapsed ? item.name : ''}
          >
            {item.icon}
            {!isCollapsed && <span className="ml-3">{item.name}</span>}
            {!isCollapsed && item.badge && (
              <span className="ml-auto bg-blue-600 text-white text-xs font-medium px-2 py-0.5 rounded-full">
                {item.badge}
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div className={`
      h-full bg-gray-900 flex flex-col
      transition-all duration-300
      ${isCollapsed ? 'w-20' : 'w-64'}
    `}>
      {/* Mobile Close Button */}
      <button
        onClick={onCloseMobile}
        className="lg:hidden absolute top-4 right-4 p-2 text-gray-400 
          hover:text-gray-300 hover:bg-gray-800 rounded-lg"
      >
        <FaTimes size={20} />
      </button>

      {/* Logo Section */}
      <div className={`
        flex items-center h-16 px-4
        ${isCollapsed ? 'justify-center' : 'px-6 border-b border-gray-800'}
      `}>
        {!isCollapsed ? (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-600 rounded-lg flex items-center justify-center">
              <FaCar className="text-white" size={20} />
            </div>
            <h1 className="ml-3 text-lg font-bold text-white">FleetManager</h1>
          </div>
        ) : (
          <div className="w-8 h-8 bg-blue-600 rounded-lg flex items-center justify-center">
            <FaCar className="text-white" size={20} />
          </div>
        )}
      </div>

      {/* Navigation with custom scrollbar */}
      <nav className="
        flex-1 overflow-y-auto py-4 px-3
        scrollbar-thin scrollbar-track-gray-800 
        scrollbar-thumb-gray-600 hover:scrollbar-thumb-gray-500
      ">
        {menuItems.map(renderMenuSection)}
      </nav>

      {/* User Section */}
      <div className={`
        p-3 border-t border-gray-800
        ${isCollapsed ? 'flex flex-col items-center' : 'p-4'}
      `}>
        <div className="space-y-1">
          <button
            onClick={() => handleNavigation('/profile')}
            className={`
              w-full flex items-center px-4 py-2.5 text-sm font-medium text-gray-300
              hover:text-white hover:bg-gray-800 rounded-lg transition-colors duration-200
              ${isCollapsed ? 'justify-center' : ''}
            `}
            title={isCollapsed ? 'Profile' : ''}
          >
            <FaUser size={18} />
            {!isCollapsed && <span className="ml-3">Profile</span>}
          </button>
          <button
            onClick={() => handleNavigation('/settings')}
            className={`
              w-full flex items-center px-4 py-2.5 text-sm font-medium text-gray-300
              hover:text-white hover:bg-gray-800 rounded-lg transition-colors duration-200
              ${isCollapsed ? 'justify-center' : ''}
            `}
            title={isCollapsed ? 'Settings' : ''}
          >
            <FaCog size={18} />
            {!isCollapsed && <span className="ml-3">Settings</span>}
          </button>
          <button
            onClick={handleLogout}
            className={`
              w-full flex items-center px-4 py-2.5 text-sm font-medium text-red-400
              hover:text-red-300 hover:bg-red-500/10 rounded-lg transition-colors duration-200
              ${isCollapsed ? 'justify-center' : ''}
            `}
            title={isCollapsed ? 'Logout' : ''}
          >
            <FaSignOutAlt size={18} />
            {!isCollapsed && <span className="ml-3">Logout</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
