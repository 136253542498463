import React, { useState } from 'react';
import { 
  FaHome, FaChevronRight, FaBell, FaGasPump, FaTools, 
  FaExclamationTriangle, FaCheckCircle, FaClock, FaCar, 
  FaEnvelope, FaSms, FaFilter, FaToggleOn, FaToggleOff
} from 'react-icons/fa';
import Layout from '../components/Layout';

const Notifications = () => {
  const [filterType, setFilterType] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [notificationSettings, setNotificationSettings] = useState({
    email: {
      maintenance: true,
      fuel: true,
      deviation: true,
      completion: true
    },
    sms: {
      maintenance: false,
      fuel: true,
      deviation: true,
      completion: false
    }
  });

  const notifications = [
    {
      id: 1,
      type: 'alert',
      title: 'Low Fuel Alert',
      message: 'Toyota Camry (ABC-123) fuel level below 15%',
      time: '5 minutes ago',
      icon: FaGasPump,
      status: 'urgent',
      sent: ['email', 'sms']
    },
    {
      id: 2,
      type: 'maintenance',
      title: 'Maintenance Due',
      message: 'Ford Transit (XYZ-789) scheduled maintenance in 3 days',
      time: '1 hour ago',
      icon: FaTools,
      status: 'warning',
      sent: ['email']
    },
    // ... other notifications from NotificationsPanel
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case 'urgent':
        return 'bg-red-100 text-red-600 border-red-200';
      case 'warning':
        return 'bg-yellow-100 text-yellow-600 border-yellow-200';
      case 'alert':
        return 'bg-orange-100 text-orange-600 border-orange-200';
      case 'success':
        return 'bg-green-100 text-green-600 border-green-200';
      default:
        return 'bg-gray-100 text-gray-600 border-gray-200';
    }
  };

  const toggleNotificationSetting = (channel, type) => {
    setNotificationSettings(prev => ({
      ...prev,
      [channel]: {
        ...prev[channel],
        [type]: !prev[channel][type]
      }
    }));
  };

  return (
    <Layout>
      <div className="min-h-screen bg-[#f5f5f5]">
       

        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Header */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
              <div>
                <h1 className="text-2xl font-semibold text-[#262626]">Notifications</h1>
                <p className="text-sm text-gray-500 mt-1">Manage your notifications and alerts</p>
              </div>
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search notifications..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 
                      focus:ring-blue-500 focus:border-blue-500"
                  />
                  <FaFilter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="border border-gray-300 rounded-lg py-2 px-3 focus:ring-2 
                    focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="all">All Types</option>
                  <option value="alert">Alerts</option>
                  <option value="maintenance">Maintenance</option>
                  <option value="deviation">Deviations</option>
                  <option value="completion">Completions</option>
                </select>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Notifications List */}
            <div className="lg:col-span-2 space-y-4">
              {notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`
                    bg-white p-4 rounded-xl border ${getStatusColor(notification.status)}
                    hover:shadow-md transition-shadow duration-200
                  `}
                >
                  <div className="flex items-start space-x-3">
                    <div className="flex-shrink-0">
                      <notification.icon size={20} />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium">{notification.title}</h3>
                        <div className="flex items-center space-x-2">
                          {notification.sent.includes('email') && (
                            <FaEnvelope size={12} className="text-gray-400" />
                          )}
                          {notification.sent.includes('sms') && (
                            <FaSms size={12} className="text-gray-400" />
                          )}
                        </div>
                      </div>
                      <p className="text-sm mt-1">{notification.message}</p>
                      <div className="flex items-center mt-2 text-xs text-gray-500">
                        <FaClock size={12} className="mr-1" />
                        {notification.time}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Settings Panel */}
            <div className="bg-white rounded-xl shadow-sm border border-gray-200/60 p-6">
              <h2 className="text-lg font-semibold mb-4">Notification Settings</h2>
              
              {/* Email Settings */}
              <div className="mb-6">
                <h3 className="text-sm font-medium text-gray-700 mb-3">Email Notifications</h3>
                <div className="space-y-3">
                  {Object.entries(notificationSettings.email).map(([type, enabled]) => (
                    <div key={type} className="flex items-center justify-between">
                      <span className="text-sm text-gray-600 capitalize">{type}</span>
                      <button
                        onClick={() => toggleNotificationSetting('email', type)}
                        className={`text-2xl ${enabled ? 'text-blue-500' : 'text-gray-300'}`}
                      >
                        {enabled ? <FaToggleOn /> : <FaToggleOff />}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* SMS Settings */}
              <div>
                <h3 className="text-sm font-medium text-gray-700 mb-3">SMS Notifications</h3>
                <div className="space-y-3">
                  {Object.entries(notificationSettings.sms).map(([type, enabled]) => (
                    <div key={type} className="flex items-center justify-between">
                      <span className="text-sm text-gray-600 capitalize">{type}</span>
                      <button
                        onClick={() => toggleNotificationSetting('sms', type)}
                        className={`text-2xl ${enabled ? 'text-blue-500' : 'text-gray-300'}`}
                      >
                        {enabled ? <FaToggleOn /> : <FaToggleOff />}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notifications; 